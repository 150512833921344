<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="SOPORTE CAMPUS VIRTUAL"
            />

            <base-body space="0">
              <span class="white--text">
                Celular: 3175017986
              </span>
              <br>
              <span class="white--text">
                WhatsApp: 3113886643
              </span>
              <br>
              <span class="white--text">
                Correo: soportemoodle@ieindustrialtulua.edu.co
              </span>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <base-img
              class="mx-auto mb-6"
              :src="require('@/assets/logo.png')"
              width="150"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionContactUs',
  }
</script>
